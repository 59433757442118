<template>
    <!--<v-virtual-scroll :items="items"
                      :item-height="60"
                      max-height="850px">
        <template v-slot="{ item }">
            <v-list-item>
                <ListItem v-bind:lisItem="{type: type, data: item}" :key="item.id" ref="listItem" v-on:Edit="redirectToEditPage($event)" v-on:Delete="DeleteItem($event)" v-on:clickedItem="itemclicked($event)"></ListItem>
            </v-list-item>
        </template>
    </v-virtual-scroll>-->

    <v-container fluid
                 grid-list-lg
                 style="max-height: 850px; overflow: auto;">
        <v-layout row wrap>
            <ListItem v-for="item in items" 
                      v-bind:lisItem="{type: type, data: item}" 
                      :key="item.id"
                      ref="listItem" 
                      v-on:Edit="redirectToEditPage($event)" 
                      v-on:Delete="DeleteItem($event)"
                      v-on:clickedItem="itemclicked($event)"></ListItem>
        </v-layout>
    </v-container>

</template>

<script>

    export default {
        components: {
            ListItem: () => import('./listItem.vue')
        },
        props: ['liItem'],
        data() {
            return {
                type: '',
            }
        },
        computed: {
            items() {
                return this.liItem.data
            },
        },
        methods: {
            redirectToEditPage: function (itemId) {
                this.$emit('Edit', itemId)
            },
            DeleteItem: function (lItem) {
                this.$emit('Delete', lItem)
            },
            itemclicked: function (item) {
                this.$emit('clickedItem', item)
            }
        },
        beforeMount() {
            this.type = this.liItem.type
        }
    }
</script>